<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="layout--main"
       :class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}, {'mobile-mode': isMobile}]">

    <v-nav-menu
      :navMenuItems="navMenuItems"
      :title="$store.state.setting.clubInfo.club_name || 'Federation'"
      parent=".layout--main"/>

    <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
      <div id="content-overlay"/>

      <!-- Navbar -->
      <the-main-navbar-mobile v-if="isMobile" />
      <the-main-navbar v-else/>
      <!-- /Navbar -->

      <div class="content-wrapper">

        <div class="router-view">
          <div class="router-content">

            <div class="content-area__content">

              <back-to-top bottom="10%" :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'" visibleoffset="500"
                           v-if="!hideScrollToTop">
                <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top"/>
              </back-to-top>

              <!-- The Content Navbar -->
              <the-content-navbar-mobile v-if="isMobile && (['saleCalendar', 'insertSaleInvoice', 'insertPastSaleInvoice'].indexOf($route.name) > -1)"/>
              <the-content-navbar v-else-if="$route.name !== 'home'"/>
              <!-- /The Content Navbar -->

              <!--              <transition :name="routerTransition" mode="out-in">-->
              <div class="content-view-box">
                <router-view @changeRouteTitle="changeRouteTitle"
                             @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)"/>
              </div>
              <!--              </transition>-->
            </div>
          </div>
        </div>
      </div>


      <the-footer/>

      <!-- The Mobile Footer -->
      <the-mobile-footer v-if="windowWidth <= $store.state.mobileScreen"/>
      <!-- /The Mobile Footer -->
    </div>
  </div>
</template>


<script>
import BackToTop from 'vue-backtotop'
import HNavMenu from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue'
import navMenuItems from '@/layouts/components/vertical-nav-menu/navMenuItems.js'
import TheNavbarHorizontal from '@/layouts/components/navbar/TheNavbarHorizontal.vue'
import TheNavbarVertical from '@/layouts/components/navbar/TheNavbarVertical.vue'
import TheFooter from '@/layouts/components/TheFooter.vue'
import themeConfig from '@/../themeConfig.js'
import VNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'
import TheContentNavbar from '@/layouts/components/navbar/TheContentNavbar'
import TheMobileFooter from '@/layouts/components/footer/TheMobileFooter'
import TheMainNavbar from '@/layouts/components/navbar/TheMainNavbar'
import TheMainNavbarMobile from "../components/navbar/TheMainNavbarMobile";
import TheContentNavbarMobile from "../components/navbar/TheContentNavbarMobile";
import moment from 'moment-jalaali'

export default {
  components: {
    TheContentNavbarMobile,
    TheMainNavbarMobile,
    BackToTop,
    HNavMenu,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu,
    TheContentNavbar,
    TheMainNavbar,
    TheMobileFooter
  },
  data() {
    return {
      footerType: themeConfig.footerType || 'static',
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || '#fff',
      navbarType: themeConfig.navbarType || 'floating',
      navMenuItems,
      routerTransition: themeConfig.routerTransition || 'none',
      routeTitle: this.$route.meta.pageTitle
    }
  },
  watch: {
    '$route'() {
      this.routeTitle = this.$route.meta.pageTitle
      this.$store.dispatch('updateContentNavbarStyle', '')
      this.$store.dispatch('updateContentNavbarClass', '')
    },
    isThemeDark(val) {
      const color = this.navbarColor === '#fff' && val ? '#10163a' : '#fff'
      this.updateNavbarColor(color)
    },
    '$store.state.mainLayoutType'(val) {
      this.setNavMenuVisibility(val)
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.windowWidth < this.$store.state.mobileScreen
    },
    bodyOverlay() {
      return this.$store.state.bodyOverlay
    },
    contentAreaClass() {
      if (this.mainLayoutType === 'vertical') {
        if (this.verticalNavMenuWidth === 'default') return 'content-area-reduced'
        else if (this.verticalNavMenuWidth === 'reduced') return 'content-area-lg'
        else return 'content-area-full'
      } else return 'content-area-full'
    },
    footerClasses() {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static'
      }
    },
    isAppPage() {
      return this.$route.meta.no_scroll
    },
    isThemeDark() {
      return this.$store.state.theme === 'dark'
    },
    layoutTypeClass() {
      return `main-${this.mainLayoutType}`
    },
    mainLayoutType() {
      return this.$store.state.mainLayoutType
    },
    navbarClasses() {
      return {
        'navbar-hidden': this.navbarType === 'hidden',
        'navbar-sticky': this.navbarType === 'sticky',
        'navbar-static': this.navbarType === 'static',
        'navbar-floating': this.navbarType === 'floating'
      }
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth
    },
    windowWidth() {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title
    },
    updateNavbarColor(val) {
      this.navbarColor = val
      this.isNavbarDark = val !== '#fff'
    },
    setNavMenuVisibility(layoutType) {
      if ((layoutType === 'horizontal' && this.windowWidth >= 768) || (layoutType === 'vertical' && this.windowWidth < 768)) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
        this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')
      } else {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
      }
    }
  },
  created() {
    const color = this.navbarColor === '#fff' && this.isThemeDark ? '#10163a' : this.navbarColor
    this.updateNavbarColor(color)
    this.setNavMenuVisibility(this.$store.state.mainLayoutType)
  },
  mounted() {
    moment.loadPersian({dialect: 'persian-modern'})
  }
}

</script>

