<template>
  <div class="relative">
    <div class="vx-navbar-wrapper custom-navbar-wrapper navbar-full p-0">
      <vs-navbar class="navbar-custom navbar-skelton" :class="navbarClasses" :style="navbarStyle" :color="navbarColor">

        <feather-icon
          v-if="windowWidth <= 767"
          class="mr-3 cursor-pointer"
          icon="MenuIcon"
          @click.stop="showSidebar"/>

        <!--<bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" />-->
        <template v-if="mobileView && $route.name !== 'saleDetailReport'">
          <span id="selectDate" class="date md:text-md text-md text-primary cursor-pointer">{{ getDate }}</span>

          <date-picker element="selectDate"
                       color="#222222"
                       :locale="$i18n.locale"
                       :locale-config="localeConfig"
                       style="color: #3c3c3c !important;"/>
        </template>


        <div class="range-date">
          <!--  this date picker element is used and referenced in main content navbar because of z-index create it here  -->
          <date-picker element="selectRangeDate"
                       style="color: #3c3c3c !important;"
                       color="#222222"
                       :range="true"
                       :min="getCalendarMin"
                       :max="getCalendarMax"
                       v-model="date"/>
        </div>


        <!--<div class="vx-logo cursor-pointer w-5/12 md:w-1/4 mx-auto flex items-center">
          &lt;!&ndash;<logo class="w-10 mr-4 fill-current text-primary" />
          <span class="vx-logo-text text-primary">Vuexy</span>&ndash;&gt;
          <custom-select class="text-center" :options="[{label: 'فروشگاه تنیس آریا', value: 1},{label: 'فروشگاه تنیس آریا2', value: 2},]"/>
        </div>-->
        <vs-spacer/>

<!--        <i18n class="mr-5"/>-->

        <notification-drop-down class="md:mr-5 mr-2"/>


<!--        <search-bar class="mr-4" />-->

        <profile-drop-down />

      </vs-navbar>
    </div>
  </div>
</template>

<script>
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import Bookmarks from './components/Bookmarks.vue'
import I18n from './components/I18n.vue'
import SearchBar from './components/SearchBar.vue'
import CartDropDown from './components/CartDropDown.vue'
import NotificationDropDown from './components/NotificationDropDown.vue'
import ProfileDropDown from './components/ProfileDropDown.vue'
import Logo from '../Logo.vue'
import moment from 'moment-jalaali'
import {mapGetters} from 'vuex'
import CustomSelect from '@/components/customSelect/customSelect'
import {checkUserPermissions, getTimeFromServer} from "../../../assets/js/functions";
import CustomIcon from "../../../components/customIcon/customIcon";
import FinancialYearList from "../../../views/admin/settings/financialYear/list/financialYearList";

export default {
  name: 'theMainNavbar',
  data () {
    return {
      route: this.$route,
      actions: this.navbarActions,
      showFinancialYearPromptStatus: false,
      financialYear: {
        startYear: '',
        endYear: ''
      },
      date: '',
      localeConfig: {
        fa: {
          dir: 'rtl',
          displayFormat: this.$validator('moment.date'),
          lang: {
            label: 'FA',
            submit: this.$t('datePicker.labels.submit'),
            cancel: this.$t('datePicker.labels.cancel'),
            now: this.$t('datePicker.labels.now'),
            nextMonth: this.$t('datePicker.labels.nextMonth'),
            prevMonth: this.$t('datePicker.labels.prevMonth')
          }
        },
        ar: {
          dow: 0,
          dir: 'rtl',
          displayFormat: vm => {
            // vm.type = date | time | datetime | year | month | yearmonth
            switch (vm.type) {
              case 'date':
                return this.$validator('moment.date')
              case 'datetime':
                return this.$validator('moment.dateTime')
              case 'year':
                return this.$validator('moment.year')
              case 'month':
                return this.$validator('moment.month')
              case 'yearmonth':
                return this.$validator('moment.yearMonth')
              case 'time':
                return this.$validator('moment.time')
            }
          },
          lang: {
            label: 'AR',
            submit: this.$t('datePicker.labels.submit'),
            cancel: this.$t('datePicker.labels.cancel'),
            now: this.$t('datePicker.labels.now'),
            nextMonth: this.$t('datePicker.labels.nextMonth'),
            prevMonth: this.$t('datePicker.labels.prevMonth')
          }
        }
      },
    }
  },
  props: {
    logo: {type: String},
    navbarType: {
      type: String,
      default: 'floating'
    }
  },
  components: {
    FinancialYearList,
    CustomIcon,
    CustomSelect,
    Logo,
    Bookmarks,
    I18n,
    SearchBar,
    CartDropDown,
    NotificationDropDown,
    ProfileDropDown,
    datePicker: VuePersianDatetimePicker
  },
  computed: {
    getDate () {
      let format = this.$validator('moment.fullDateWithWeek')
      return moment(getTimeFromServer()).locale(this.$i18n.locale).format(format)
    },
    mobileView () {
      return  this.$store.state.windowWidth > this.$store.state.mobileScreen
    },
    getCalendarMin() {
      if (this.$route.name === 'insertSaleInvoice') {
        return this.date
      } else {
        return null
      }
    },
    getCalendarMax() {
      if (this.$route.name === 'insertPastSaleInvoice') {
        return this.date
      } else {
        return null
      }
    },
    navbarColor () {
      let color = '#fff'
      if (this.navbarType === 'sticky') color = '#f7f7f7'
      else if (this.navbarType === 'static') {
        if (this.scrollY < 50) {
          color = '#f7f7f7'
        }
      }

      if (this.isThemedark === 'dark') {
        if (color === '#fff') {
          color = '#131314'
        } else {
          color = '#131314'
        }
      }

      return color
    },
    isThemedark () {
      return this.$store.state.theme
    },
    navbarStyle () {
      return this.navbarType === 'static' ? {transition: 'all .25s ease-in-out'} : {}
    },
    navbarClasses () {
      return this.scrollY > 5 && this.navbarType === 'static' ? null : 'd-theme-dark-light-bg shadow-none'
    },
    scrollY () {
      return this.$store.state.scrollY
    },
    verticalNavMenuWidth () {
      return this.$store.state.verticalNavMenuWidth
    },
    windowWidth () {
      return this.$store.state.windowWidth
    },

    ...mapGetters(['navbarActions'])
  },
  created () {
    const date = getTimeFromServer()
    this.date = moment(date).format(this.$validator('moment.date'))
    this.getFinancialYearInfo()
  },
  methods: {
    eventHandler (value, type) {
      switch (type) {
      case 'link':
        this.$router.push(value)
        break

      default:
        document.getElementById(`${value}`).click()
        break
      }
    },
    showSidebar () {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
    },

    getFinancialYearInfo () {
      this.financialYear = this.$store.state.auth.user.financial_year
      if (this.financialYear) {
        this.financialYear.startYear = this.financialYear.started_at.split('/')[0]
        this.financialYear.endYear = this.financialYear.finished_at.split('/')[0]
      } else {
        this.financialYear = {
          startYear: '',
          endYear: ''
        }
      }
    },

    handleShowFinancialYearClicked () {
      if (checkUserPermissions('financial_year.switch')) {
        this.showFinancialYearPromptStatus = true
      }
    }
  },
  watch: {
    '$route' () {
      this.$store.dispatch('updateNavbarActions', [])
      this.route = this.$route
    },
    '$store.state.navbarActions': {
      handler (val) {
        this.actions = val
      },
      deep: true
    },
    date: {
      handler(val) {
        this.$store.dispatch('helper/changeCalendarDate', val)
      }
    },
    '$store.state.auth.user.financial_year': {
      handler (val) {
        this.getFinancialYearInfo()
      },
      deep: true
    }
  }
}

</script>

<style lang="scss">
.vx-navbar-wrapper {

  &.custom-navbar-wrapper {
    height: fit-content;
    background: transparent !important;
    box-shadow: 0 0 15px #333333;

    .vpd-actions {
      display: none;
    }

    .range-date {
      .vpd-actions {
        display: block;
      }
    }
  }

  .financial-year-label {
    border: 1px solid #cdcdcd;
    margin: 0 5px;
    padding: 5px 10px;
    color: #48b4e0;
    border-radius: 0.5rem;

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.01);
    }
  }

  .date {
    /*margin: 0 15px;*/
  }

  .useral-custom-element-select {

    .selected {
      background-color: #131314;
      border-radius: 0;
      cursor: pointer;
      border-bottom: 1px solid #333333;
    }

    .open {
      border-bottom: 0;
    }

    .items {
      border-radius: 0px 0px 3px 3px;
      box-shadow: 0px 5px 10px black;
      background-color: #131314;

      div:hover {
        background-color: #222222;
      }
    }
  }
}
</style>

