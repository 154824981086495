<template>
  <div class="relative">
    <div class="vx-navbar-wrapper content-navbar"
         :class="[classObj, contentNavbarClass, navbarSticky ? 'bottom-border-radius-0' : '']">
      <vs-navbar
        class="vx-navbar navbar-custom navbar-skelton top-0 flex"
        :color="navbarColorLocal"
        :class="[textColor, !this.$vs.rtl ? 'rtl-only' : '']">

        <!--<vs-dropdown v-if="actions && actions.list">
          <a class="a-icon" href.prevent>
            <vs-icon icon-pack="feather" icon="icon-more-vertical"></vs-icon>
          </a>

          <vs-dropdown-menu class="navbar-more-actions">
            <vs-dropdown-item v-for="(item, index) in actions.list"
                              @click.stop="eventHandler(item.id, item.type)"
                              :class="[`useral-text-${item.color}`, 'px-2']"
                              :key="index"
                              divider>
              &lt;!&ndash;              <vs-icon icon="icon-check" icon-pack="feather"/>&ndash;&gt;
              {{ $t(item.i18n) }}
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>-->

        <!--<feather-icon @click.stop="showSidebar" icon="CheckIcon" class="useral-text-success"/>-->
        <div class="flex" v-if="actions && actions.toolbar">
          <template v-for="(item, index) in actions.toolbar">
            <template
              v-if="checkUserPermissions(item.permission) && item.hasOwnProperty('type') && item.type === 'date'">
              <!--              <span id="selectRangeDate" class="md:text-md text-md text-primary cursor-pointer">{{ getDate }}</span>-->
              <div class="flex items-center" id="selectRangeDate" :class="[{'active': item.active}]">
                <custom-icon icon="CALENDER"
                             :color="item.color"
                             :width="iconSizeThreshold ? '32px' : '22px'"
                             :height="iconSizeThreshold ? '32px' : '22px'"/>
                <span class="date-range">
                  {{ $t('navbar.from') }}
                  <span class="start-time">{{ getRangeDate[0] }}</span>
                  {{ $t('navbar.until') }}
                  <span class="end-time">{{ getRangeDate[1] }}</span>
                </span>
              </div>

<!--              <date-picker element="selectRangeDate"-->
<!--                           style="color: #3c3c3c !important;"-->
<!--                           color="#222222"-->
<!--                           :range="item.hasOwnProperty('range') ? item.range : false"-->
<!--                           :min="getCalendarMin"-->
<!--                           :max="getCalendarMax"-->
<!--                           v-model="date"/>-->
            </template>
            <p :key="`right-toolbar-${index}`"
               v-else-if="checkUserPermissions(item.permission)"
               @click.stop="eventHandler(item.id, item.type)"
               class="navbar-action"
               :class="['useral-font-weight-medium inline-block cursor-pointer px-2', item.color ? `useral-text-${item.color}`: '', {'active': item.active}]"
               style="vertical-align: middle;"
               :style="item.hasOwnProperty('style') ? item.style : ''">

              <custom-icon v-if="item.icon && (item.iconPack === 'useral')"
                           :icon="item.icon"
                           :color="item.color"
                           :width="item.width ? item.width : iconSizeThreshold ? '32px' : '22px'"
                           :height="item.height ? item.height : iconSizeThreshold ? '32px' : '22px'"/>

              <vs-icon v-else-if="item.icon"
                       :icon="item.icon"
                       :icon-pack="item.iconPack"
                       :color="item.color ? item.color : ''"
                       :class="!item.color ? 'useral-text-primary' : ''"/>
              <span v-if="item.hasOwnProperty('badge') && item.badge.value > 0" class="badge"
                    :class="item.color ? `useral-color-${item.color}` : ''">
              {{ item.badge.value }}
            </span>
              {{ item.text || $t(item.i18n) }}
            </p>
          </template>

        </div>

        <div v-else-if="showFilterRange" class="filter-section">
          <div class="switch-options">
            <template v-for="(item, key) in reportType">
              <button
                :key="key"
                class="switch-item"
                :class="{'active': $store.state.reportRangeType === item.value}"
                @click="hangleChangeFilterRange(item.value)">{{ item.label }}
              </button>
            </template>
          </div>
        </div>

        <vs-spacer/>

        <template v-if="showDateInNavbar">
          <span id="selectTableDate" class="md:text-md text-md text-primary cursor-pointer">{{ getDate }}</span>

          <date-picker element="selectTableDate"
                       style="color: #3c3c3c !important;"
                       color="#222222"
                       :min="getCalendarMin"
                       :max="getCalendarMax"
                       v-model="date"/>
        </template>
        <p v-else-if="!isMobile" class="navbar-page-title useral-font-weight-bold text-lg" style="font-size: 16px">
          {{ $store.state.pageTitle || $t(route.meta.pageTitleI18n) || route.meta.pageTitle }}</p>

        <vs-spacer/>

        <div v-if="actions && actions.leftToolbar" class="flex">
          <p v-for="(item, index) in actions.leftToolbar"
             :key="`left-toolbar-${index}`"
             v-if="checkUserPermissions(item.permission)"
             @click.stop="eventHandler(item.id, item.type)"
             class="navbar-action"
             :class="['useral-font-weight-medium cursor-pointer px-2', item.color ? `useral-text-${item.color}`: '']"
             style="vertical-align: middle;"
             :style="item.hasOwnProperty('style') ? item.style : ''">

            {{ item.text || $t(item.i18n) }}

            <custom-icon v-if="item.icon && (item.iconPack === 'useral')"
                         :icon="item.icon"
                         :color="item.color"
                         :width="item.width ? item.width : iconSizeThreshold ? '32px' : '22px'"
                         :height="item.height ? item.height : iconSizeThreshold ? '32px' : '22px'"/>

            <vs-icon v-else-if="item.icon"
                     :icon="item.icon"
                     :icon-pack="item.iconPack"
                     :color="item.color ? item.color : ''"
                     :class="!item.color ? 'useral-text-primary' : ''"/>
          </p>
        </div>

        <div v-if="actions && actions.list" class="relative">
          <vs-icon @click="actionsListStatus.right = true"
                   class="useral-text-primary cursor-pointer"
                   icon-pack="feather"
                   icon="icon-more-vertical"/>

          <custom-drop-down :list="actions.list"
                            :fix-to-right="false"
                            :show="actionsListStatus.right"
                            @closed="actionsListStatus.right = false"
                            @clicked="eventHandler($event.id, $event.type)"/>
        </div>

      </vs-navbar>

      <div class="bottom-shadow"></div>
    </div>
  </div>
</template>


<script>
  import {mapGetters} from 'vuex'
  import moment from 'moment-jalaali'
  import DatePicker from 'vue-persian-datetime-picker'
  import CustomDropDown from '@/components/customDropDown/customDropDown'
  import CustomIcon from '@/components/customIcon/customIcon'
  import {checkUserPermissions, getTimeFromServer} from '../../../assets/js/functions'

  export default {
    name: 'TheContentNavbar',
    components: {CustomIcon, CustomDropDown, DatePicker},
    data() {
      return {
        route: {},
        actions: {},
        actionsListStatus: {
          right: false,
          left: false
        },
        date: '',
        reportType: [
          {
            label: 'روزانه',
            value: 1
          },
          {
            label: 'هفتگی',
            value: 2
          },
          {
            label: 'ماهانه',
            value: 3
          },
        ]
      }
    },
    props: {
      navbarColor: {
        type: String,
        default: '#fff'
      }
    },
    computed: {
      isMobile() {
        return this.$store.state.windowWidth <= this.$store.state.mobileScreen
      },
      iconSizeThreshold() {
        return this.$store.state.windowWidth > 576
      },
      getDate() {
        return moment(this.date, this.$validator('moment.date')).format(this.$validator('moment.fullDateWithWeek'))
      },
      getRangeDate() {
        if (typeof this.date === 'string') {
          return [this.date, this.date]
        } else if (this.date.length === 1) {
          return [this.date[0], this.date[0]]
        } else {
          return this.date
        }
      },
      showFilterRange() {
        // return this.$route.name === 'home'
        return false
      },
      getCalendarMin() {
        if (this.$route.name === 'insertSaleInvoice') {
          return this.date
        } else {
          return null
        }
      },
      getCalendarMax() {
        if (this.$route.name === 'insertPastSaleInvoice') {
          return this.date
        } else {
          return null
        }
      },
      showDateInNavbar() {
        if ((this.$route.name === 'saleCalendar' || this.$route.name === 'insertSaleInvoice' || this.$route.name === 'insertPastSaleInvoice') && this.$store.state.windowWidth < this.$store.state.mobileScreen) {
          return true
        } else {
          return false
        }
      },
      navbarColorLocal() {
        return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor
      },
      verticalNavMenuWidth() {
        return this.$store.state.verticalNavMenuWidth
      },
      contentNavbarStyle() {
        return this.$store.state.contentNavbarStyle
      },
      contentNavbarClass() {
        return this.$store.state.contentNavbarClass
      },
      textColor() {
        return {
          'text-white':
            (this.navbarColor !== '#10163a' &&
              this.$store.state.theme === 'dark') ||
            (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark')
        }
      },
      windowWidth() {
        return this.$store.state.windowWidth
      },

      // NAVBAR STYLE
      classObj() {
        if (this.verticalNavMenuWidth === 'default') return 'navbar-default'
        else if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced'
        else if (this.verticalNavMenuWidth) return 'navbar-full'
      },

      navbarSticky() {
        return this.contentNavbarStyle === 'sticky'
      },

      ...mapGetters(['navbarActions'])
    },
    created() {
      this.route = this.$route
      const date = getTimeFromServer()
      this.date = moment(date).format(this.$validator('moment.date'))
      this.actions = this.navbarActions
    },
    methods: {
      getCalendarDate(date) {
        return moment(date, this.$validator('moment.date')).format(this.$validator('moment.fullDateWithWeek'))
      },
      showSidebar() {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
      },
      eventHandler(value, type) {
        switch (type) {
          case 'link':
            this.$router.push(value)
            break

          case 'new-page-link':
            window.open(this.$router.resolve(value).href, '_blank')
            break

          default:
            document.getElementById(`${value}`).click()
            break
        }
      },
      checkUserPermissions(permission) {
        return permission ? checkUserPermissions(permission) : true
      },
      hangleChangeFilterRange(value) {
        this.$store.dispatch('changeFilterRange', value)
      }
    },
    watch: {
      '$route'() {
        this.$store.dispatch('updateNavbarActions', {})
        this.$store.dispatch('setPageTitle', '')
        this.route = this.$route
      },
      '$store.state.navbarActions': {
        handler(val) {
          this.actions = val
        },
        deep: true
      },
      'navbarActions': {
        handler(val) {
          this.actions = val
        },
        deep: true
      },
      '$store.state.helper.calendarDate': {
        handler(val) {
          this.date = val
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/scss/vuexy/variables';

  $primary: #3891bc;
  .vx-navbar-wrapper {
    position: relative !important;
    width: 100%;
    height: fit-content !important;
    z-index: 9000;
    border-radius: .5rem .5rem 0 0;

    .bottom-shadow {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 1px;
      box-shadow: 0 0 10px 1px #cecece;
    }

    .vx-navbar {
      border-radius: .5rem .5rem 0 0;
      padding: 0.8rem !important;

      &.rtl-only {
        direction: rtl !important;
      }
    }

    &.bottom-border-radius-0 {
      border-radius: .5rem .5rem 0 0 !important;
      border-bottom: 1px solid #333333;
    }

    &.navbar-default {
      .vs-navbar {
        //width: calc(100% - 260px) !important;
      }
    }

    &.navbar-reduced {
      .vs-navbar {
        //width: calc(100% - 80px) !important;
      }
    }

    .vx-navbar {
      height: 50px;
      border-bottom: 1px solid #03092d;

      .navbar-page-title {
        display: block;
        width: min-content;
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        white-space: nowrap;
      }

      .date-range {
        display: block;
        margin-left: 5px;

        .start-time,
        .end-time {
          color: $primary;
        }
      }

      #selectRangeDate:hover {
        cursor: pointer;
      }
    }

    i.vs-icon {
      font-size: 30px;

      @media (max-width: 576px) {
        font-size: 20px;
      }
    }

    .navbar-action {
      padding: 4px !important;
      display: flex;
      align-items: center;
      position: relative;

      .badge {
        position: absolute;
        bottom: -4px;
        right: -10px;
        color: #fff;
        background: $success;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .active {
      color: #000 !important;
      background-color: #f0f0f0;
      border-radius: 0.5rem;
    }

    .filter-section {
      height: 50px;
      position: relative;
      left: -11px;

      .switch-options {
        position: relative;
        height: 100%;

        .switch-item {
          height: 100%;
          padding: 2px 10px;
          border: 0;
          background-color: transparent;
          transition: all ease 0.5s;

          &:hover {
            cursor: pointer;
            background-color: #f0f0f0;
          }

          &.active {
            color: $primary;
            border-bottom: 2px solid $primary;
          }

          &:first-of-type {
            border-radius: 0.5rem 0 0 0;
          }
        }
      }
    }
  }

  .md-max-w-half {
    width: 50%;
    min-width: 700px;

    @media screen and (max-width: 800px) {
      min-width: unset;
      width: 100%;
    }
  }
</style>

